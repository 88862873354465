import axios from 'axios';
// import Vue from 'vue';
import store from './store';
// import router from './router';

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: false,
});

const { getters } = store;

export default {
  install(vue) {
    vue.prototype.$api = {
      async axiosPost(url, body, onSuccess, onError) {
        await api.post(url, body, {
          crossDomain: true,
          headers: this.getHeaders(),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      async axiosDelete(url, body, onSuccess, onError) {
        await api.delete(url, {
          crossDomain: true,
          headers: this.getHeaders(),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      async axiosPatch(url, body, onSuccess, onError) {
        await api.patch(url, body, {
          crossDomain: true,
          headers: this.getHeaders(),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      async axiosPut(url, body, onSuccess, onError) {
        await api.put(url, body, {
          crossDomain: true,
          headers: this.getHeaders(),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      async axiosPostFormData(url, body, onSuccess, onError) {
        await api.post(url, body, {
          headers: this.getHeaders([
            { key: 'Content-Type', value: 'multipart/form-data' },
          ]),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      async axiosGet(url, body, onSuccess, onError) {
        await api.get(url, {
          params: body,
          headers: this.getHeaders(),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      async axiosDownload(url, body, onSuccess, onError) {
        await api.get(url, {
          params: body,
          headers: this.getHeaders(),
          responseType: 'blob',
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      getHeaders(replaceHeaders) {
        const headers = {
          Accept: 'application/json',
          ContentType: 'application/json',
        };
        const accessToken = getters['user/accessToken'];
        if (typeof accessToken !== 'undefined' && accessToken) {
          headers.Authorization = `Bearer ${accessToken}`;
        }
        if (replaceHeaders) {
          replaceHeaders.forEach((replace) => {
            headers[replace.key] = replace.value;
          });
        }

        return headers;
      },

      login(body, onSuccess, onError) {
        return this.axiosPost('/login', body, onSuccess, onError);
      },
      // Users
      getUsers(body, onSuccess, onError) {
        return this.axiosGet('/users', body, onSuccess, onError);
      },
      filterUsers(query, onSuccess, onError) {
        return this.axiosGet(`/users?${query}`, false, onSuccess, onError);
      },
      createUser(body, onSuccess, onError) {
        return this.axiosPost('/users', body, onSuccess, onError);
      },
      restoreUser(body, onSuccess, onError) {
        return this.axiosPost('/users/restore', body, onSuccess, onError);
      },
      deleteUser({ uuid }, onSuccess, onError) {
        return this.axiosDelete(`/users/${uuid}`, null, onSuccess, onError);
      },
      getUser({ uuid }, onSuccess, onError) {
        return this.axiosGet(`/users/${uuid}`, null, onSuccess, onError);
      },
      getAccount(onSuccess, onError) {
        return this.axiosGet('/account/', null, onSuccess, onError);
      },
      downloadDocumentation(service, onSuccess) {
        return this.axiosDownload(`/services/${service}/documentation`, null, onSuccess, () => {
          vue.prototype.$notification.warning({
            message: 'Download error',
          });
        });
      },
      downloadReport(service, onSuccess) {
        return this.axiosDownload(`/services/${service}?download=true`, null, onSuccess, () => {
          vue.prototype.$notification.warning({
            message: 'Download error',
          });
        });
      },
      getUserTransactions(body, onSuccess, onError) {
        return this.axiosGet('/account/transactions', body, onSuccess, onError);
      },
      getIpAddressesOfUser({ uuid }, onSuccess, onError) {
        return this.axiosGet(`/users/${uuid}/ips`, null, onSuccess, onError);
      },
      saveIpAddresses({ uuid }, body, onSuccess, onError) {
        return this.axiosPost(`/users/${uuid}/ips`, body, onSuccess, onError);
      },
      // Transactions
      getTransactions(body, onSuccess, onError) {
        return this.axiosGet('/transactions', body, onSuccess, onError);
      },
      fundDeposit(body, onSuccess, onError) {
        const { user } = body;
        return this.axiosPost(`/account/${user}/fund-deposit`, body, onSuccess, onError);
      },
      // Transactions
      getRoles(onSuccess, onError) {
        return this.axiosGet('/roles', {}, onSuccess, onError);
      },
      getCurrencies(onSuccess, onError) {
        return this.axiosGet('/currencies', {}, onSuccess, onError);
      },
      // Transactions
      getServices(onSuccess, onError) {
        return this.axiosGet('/services', {}, onSuccess, onError);
      },
      getServiceOrders(service, body, onSuccess, onError) {
        return this.axiosGet(`/services/${service}`, body, onSuccess, onError);
      },
      makeRefund({ uuid }, onSuccess, onError) {
        return this.axiosPatch(`/transactions/${uuid}`, {}, onSuccess, onError);
      },
      cancelDeposit({ uuid }, onSuccess, onError) {
        return this.axiosDelete(`/transactions/${uuid}`, {}, onSuccess, onError);
      },
      changeServiceState({ service, state }, onSuccess, onError) {
        return this.axiosPost(`/services/${service}/state`, { state }, onSuccess, onError);
      },

      // eslint-disable-next-line camelcase
      cancelInsurance({ policy_number, provider }, onSuccess, onError) {
        const requestData = {
          // eslint-disable-next-line camelcase
          policy_number,
          provider,
        };
        return this.axiosPost('/insurance/cancel', requestData, onSuccess, onError);
      },
    };
  },
};
