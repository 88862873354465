import * as MUTATION from './mutation-types';

export default {
  logout({ commit }) {
    commit(MUTATION.REMOVE_TOKEN);
  },
  setUser({ commit }, user) {
    commit(MUTATION.SET_USER, user);
  },
  setToken({ commit }, token) {
    commit(MUTATION.SET_TOKEN, token);
  },
  setServices({ commit }, services) {
    commit(MUTATION.SET_SERVICES, services);
  },
};
