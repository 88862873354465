import Cookies from 'js-cookie';
import store from '../../store';

export default async (to, from, next) => {
  if (typeof store.getters['user/accessToken'] === 'undefined' || !store.getters['user/accessToken']) {
    Cookies.set('intended_url', to.path);

    next({ name: 'login' });
  } else {
    next();
  }
};
